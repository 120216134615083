body {
  margin: 0;

  font-family: 'Urbanist', sans-serif;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');